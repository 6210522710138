<template>
    <div id="frame-box">
        <div id="left-menu">
            <ul id="left-menu-ul">
                <li v-for="(menu, index) in menus" v-bind:key="index"
                    :class="{selected:menuSelected === menu}"
                    @click="menuSelect(menu)">
                    <a>{{ menu }}</a></li>
            </ul>
        </div>
        <div id="right-main">
        </div>
    </div>
</template>

<script>
export default {
    name: 'coupon',
    data() {
        return {
            menus: ['常用', '宠物', '家电', '数码', '钓具'],
            menuSelected: '',
        }
    },
    methods: {
        menuSelect(val) {
            this.menuSelected = val
        },
    },
    created() {
        this.menuSelected = this.menus[0]
    },
}
</script>

<style scoped>

#frame-box {
    height: 85%;
    width: 85%;
    margin: 0 auto;
    padding: 57px 0 0 0;
    min-width: 640px;
}

#left-menu {
    height: auto;
    margin: 28px 0;
    float: left;
}

#left-menu ul li {
    text-align: center;
    list-style: none;
    height: 40px;
    padding: 0;
    margin: 6px 0;
}

#left-menu ul li a {
    vertical-align: middle;
    height: 32px;
    line-height: 32px;
    padding: 0 16px;
    margin: 4px 0;
}

#right-main {
    font-size: 14px;
    padding: 0 0 0 10px;
    margin: 28px 0 0 0;
    line-height: 32px;
    height: 100%;
    width: auto;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: normal;
    word-break: break-all;
}

#right-main span {
    line-height: 24px;
    margin: 0;
    padding: 0 0 3px 0;
    border-bottom: 2px solid #838383;
    cursor: text;
    color: #999999;
    font-size: 14px;
}
</style>